







































import { Component, Vue } from 'vue-property-decorator';
import label from '@/resources/labels/error.json';

@Component
export default class ErrorPage extends Vue {
  private readonly label: any = label;
  private errMsg1 = '';
  private errMsg2 = '';

  private mounted() {
    const errDesc: any = this.$route.query.error_description;
    this.errMsg1 = (this.label[errDesc] || {}).msg1 || errDesc;
    this.errMsg2 = (this.label[errDesc] || {}).msg2;
  }
}
